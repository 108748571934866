import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
function App() {
  return (
    <>
     <Header />
    </>
  );
}

export default App;
