import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import HistoryIcon from "@mui/icons-material/History";
import AddIcon from "@mui/icons-material/Add";
import Fade from "react-reveal/Fade";

uuidv4();

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];
const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [completdTask, setCompletedTask] = useState([]);

  const [todo, setTodo] = useState([]);

  const [editTodo, setEditTodo] = useState("");
  const [todoValue, setTodoValue] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setCompletedTask(todo.filter((todos) => todos?.completed === true));
  }, [todo]);

  const handleSingleTodo = (text) => {
    setTodo([
      ...todo,
      { id: uuidv4(), text, completed: false, isEditing: false },
    ]);

    setTodoValue("");
  };

  const handleInput = (event) => {
    console.log("test=>", event.target.value === "");
    setTodoValue(event.target.value);
    if (event.target.value === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const editTask = (text, id) => {
    setTodo(
      todo.map((todos) =>
        todos.id === id
          ? { ...todos, text, isEditing: !todos.isEditing }
          : todos
      )
    );
  };

  //   const handleEdit = (text, index) => {
  //   console.log(text)
  //     const editedTodos = [{id: index, text: text, isEdit: false}];
  //     setEditTodo(editedTodos);
  //    }

  const markTodo = (id) => {
    setTodo(
      todo.map((items) =>
        items.id === id ? { ...items, completed: !items.completed } : items
      )
    );
  };

  const handleAddTodo = () => {
    if (todoValue === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
    if (todoValue) {
      handleSingleTodo(todoValue);
    }
  };

  const handleRemoveTodo = (indexValue) => {
    const newTodos = [...todo];
    newTodos.splice(indexValue, 1);
    setTodo(newTodos);

    //  const filteredTodo = todo.filter((item, index) => (indexValue !== index));
    //  console.log("filteredTodo=>", filteredTodo);
    //  setTodo(filteredTodo);
  };

  const handleEdit = (event) => {
    setEditTodo(event.target.value);
    if (event.target.value === "") {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };
  const handleEditTodo = (text, indexValue) => {
    //   const filteredTodo = todo.find((task, index) => (index === indexValue))

    //   console.log('filteredTodo', filteredTodo)

    // if()  {

    // const editedTodo = prompt('Edit the todo:');
    // if (editedTodo !== null && editedTodo.trim() !== '') {

    todo.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (indexValue === index) {
        let updatedTodos = [...todo];
        updatedTodos[index].text = editTodo;
        updatedTodos[index].isEdited = true;

        console.log("editTodo", editTodo);
        setTodo(updatedTodos);
        setEditTodo("");
      }
    });

    //     }
    //   this.setState({
    //     list: updatedTodos,
    // });

    // }
    //  console.log('editTodo', editTodo)

    //   const newTodos = [...todo];
    //  newTodos[indexValue] = {text: editTodo, isDone: false};

    // todo[1].text = 'mango'
    // const newState = todo.map((obj, index) => {
    //   // 👇️ if id equals 2, update country property
    //   if (filteredTodo?.text === obj?.text) {
    //     return {text: editTodo, isEdit: true};
    //   }

    //   // 👇️ otherwise return the object as is
    //   return obj;
    // });
    //   setTodo(newTodos);

    // newState[indexValue].isDone = doneStatus;

    // setDoneStatus(!doneStatus);

    //  setTodo(newState)

    console.log("todo=>", todo);
    // const newTodos = [{text, isEdit: true}];
    // setEditTodo(newTodos);
    // console.log('newTodos=>', newTodos)
    // console.log('edit=>', editTodo)
    // console.log('filtered', filteredTodo)
    // setCompletedTask(completed);
  };

  // handleSingleTodo(todoValue);
  console.log("editTodo=>", editTodo);
  console.log("compltedTask==>");
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            MUI
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff" }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ p: 3, marginLeft: "auto", marginRight: "auto" }}
      >
        <Card sx={{ minWidth: 650, mt: 12, border: "1px solid gray" }}>
          <CardContent>
            <Box
              sx={{
                p: 3,
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                gap: "15px",
              }}
            >
              <TextField
                id="outlined-basic"
                label="Add todo"
                sx={{ width: "70%" }}
                variant="outlined"
                value={todoValue}
                error={false ? true : false}
                onChange={(event) => handleInput(event)}
              />
              <Tooltip title="Add" placement="top">
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: "30%" }}
                  onClick={handleAddTodo}
                  type={"submit"}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Box>

            {showError && (
              <p
                style={{
                  marginTop: "-8px",
                  marginLeft: "26px",
                  color: "#ff0000",
                }}
              >
                This field is required
              </p>
            )}

            <List
              sx={{ width: "100%", maxWidth: 650, bgcolor: "background.paper" }}
            >
              {todo.map((value, index) => {
                const labelId = `checkbox-list-label-${value}`;
                console.log("check=>", value);
                return (
                  <>
                    <Fade top>
                      <ListItem
                        key={value}
                        disablePadding
                        sx={{ gap: "22px", marginTop: "10px" }}
                      >
                        {value.isEditing ? (
                          <>
                            <TextField
                              id="outlined-basic"
                              label="Edit todo"
                              sx={{ width: "70%" }}
                              variant="outlined"
                              onChange={(event) => handleEdit(event)}
                              defaultValue={value?.text}
                            />
                            {showError && (
                              <p
                                style={{
                                  marginTop: "-8px",
                                  marginLeft: "26px",
                                  color: "#ff0000",
                                }}
                              >
                                This field is required
                              </p>
                            )}
                          </>
                        ) : (
                          <ListItemText
                            id={labelId}
                            style={{
                              textDecoration: value?.completed
                                ? "line-through"
                                : "",
                              marginLeft: "22px",
                              marginTop: "16px",
                            }}
                            primary={`${index + 1} ${value.text}`}
                          />
                        )}

                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => markTodo(value.id)}
                        >
                          {value?.completed ? (
                            <Tooltip title="Undo" placement="top">
                              <HistoryIcon />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Done" placement="top">
                              <CheckIcon />
                            </Tooltip>
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => editTask(editTodo, value.id)}
                        >
                          <Tooltip title="Edit" placement="top">
                            <EditIcon />
                          </Tooltip>
                        </Button>
                        <Tooltip title="Delete" placement="top">
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveTodo(index)}
                          >
                            <DeleteIcon />
                          </Button>
                        </Tooltip>
                      </ListItem>
                    </Fade>
                  </>
                );
              })}
            </List>
            {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Word of the Day
        </Typography>
        {/* <Typography variant="h5" component="div">
          be{bull}nev{bull}o{bull}lent
        </Typography> */}
          </CardContent>
        </Card>

        {completdTask?.length > 0 && (
          <Fade left>
            <Card sx={{ minWidth: 650, mt: 5, border: "1px solid green" }}>
              <CardHeader title="Completed task" sx={{ color: "green" }} />

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 650,
                      bgcolor: "background.paper",
                    }}
                  >
                    {completdTask.map((value, index) => {
                      const labelId = `checkbox-list-label-${value}`;
                      console.log("check=>", value?.isDone);
                      return (
                        <>
                          <ListItem
                            key={value}
                            disablePadding
                            sx={{ gap: "22px" }}
                          >
                            <ListItemText
                              id={labelId}
                              style={{
                                background: todo.isDone ? "red" : "",
                                marginLeft: "22px",
                                marginTop: "16px",
                              }}
                              primary={`${index + 1} ${value.text}`}
                            />
                          </ListItem>
                        </>
                      );
                    })}
                  </List>
                </Typography>
              </CardContent>
            </Card>
          </Fade>
        )}
      </Box>
    </Box>
  );
};

export default Header;
